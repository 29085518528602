<template>
  <div>
    <v-navigation-drawer v-model="showMenubar" :width="$vuetify.breakpoint.xsOnly ? '75vw' : 333" temporary fixed>
      <template #prepend>
        <v-card tile flat :color="$vuetify.theme.dark ? '' : systemDetails.themecolor" height="280">
          <!-- <v-btn icon absolute right dark class="mt-3 mr-n2" @click="toggleAppTheme"> --> <!-- uncomment this line and comment the next one to have the theme button on the right side -->
          <v-btn icon absolute dark class="ma-4" @click="toggleAppTheme">
            <v-icon v-if="$vuetify.theme.dark"> mdi-white-balance-sunny </v-icon>
            <v-icon v-else> mdi-moon-waning-crescent </v-icon>
          </v-btn>
          <div class="d-flex justify-center pt-7">
            <div>
              <div style="position: relative;">
                <v-avatar size="150">
                  <v-img :src="userDetails.userimage ? `${$_imageUrl}/${userDetails.userimage}` : require('@/assets/profile.jpg')" alt="User profile"
                    :lazy-src="userDetails.userimage ? `${$_imageUrl}/${userDetails.userimage}` : require('@/assets/profile.jpg')">
                  </v-img>
                </v-avatar>
                <v-menu absolute nudge-right="3">
                  <template #activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" :color="$vuetify.theme.dark ? '' : systemDetails.textcolor" size="23" class="pa-0 profilepage-camera">
                      mdi-camera
                    </v-icon>
                  </template>
                  <v-list dense class="pb-2 pt-0">
                    <v-list-item dense v-for="(item, index) in profileMenuList" :key="index" @click="item.click()" class="py-1 my-0 pl-3 pr-4 custom-vlist-item mb-n2">
                      <v-list-item-action class="my-1 mr-2" v-if="item.icon">
                        <v-icon size="21"> {{ item.icon }} </v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title class="subtitle-2" v-text="$t(item.text)"></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
              <v-file-input ref="file" accept="image/png, image/jpeg, image/bmp" v-model="avatar" @change="uploadProfile" class="d-none"></v-file-input>
            </div>
          </div>
          <div class="text-center font-weight-medium white--text">
            <p class="mt-2 mb-0 text-h6"> {{ userDetails.name }} </p>
            <span :class="['body-2', $vuetify.theme.dark ? '' : 'grey--text text--lighten-2']"> {{ userDetails.email }} </span>
            <div>
              <v-icon size="17" dark> mdi-map-clock </v-icon>
              <p class="ma-0 mx-2 font-weight-medium d-inline-block" style="font-size: 0.75rem;"> {{ userTimezone }} </p>
            </div>
          </div>
        </v-card>
      </template>
      <template>
        <v-card tile flat height="100%" class="px-1 py-4" :color="$vuetify.theme.dark ? 'grey darken-4' : 'grey lighten-5'">
          <v-list dense flat>
            <v-list-group :value="true"  v-for="(item, i) in listOfTenantsAndWorkspaces" :key="i" sub-group>
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>{{ item.company }}</v-list-item-title>
                </v-list-item-content>
              </template>
              <v-list-item v-for="(workspace, ind) in item.tenants" :key="ind" link @click="switchTenant(workspace)">
                <!-- <v-list-item-title class="pl-7" v-text="workspace.name"></v-list-item-title> -->
                <span class="caption font-weight-medium pl-7">{{ workspace.name }}</span>
                <v-icon v-if="workspace._id === userDetails.tenantid" class="mx-2" size="15" color="primary lighten-2"> mdi-checkbox-marked-circle-outline </v-icon>
              </v-list-item>
              </v-list-group>
          </v-list>
          <template v-for="(item, index) in menuItems">
            <v-list-item :key="index" @click="item.click && item.click()" :class="item.class">
              <v-list-item-action class="ma-2 mb-1">
                <v-icon :size="item.iconSize || 30"> {{ item.icon }} </v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title :class="['px-2 body-1 font-weight-medium', $vuetify.theme.dark ? '' : 'grey--text text--darken-3']">
                  <span class="d-flex"> {{ item.title }} </span>
                  <span v-if="item.switchworkspace && currentlyLoggedInTenant">
                    <span class="caption"> {{ currentlyLoggedInTenant }} </span>
                    <v-icon class="ma-1" size="15" color="primary darken-1"> mdi-checkbox-marked-circle </v-icon>
                  </span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <div :key="`children_${index}`" v-if="item.children && item.children.length" class="px-12">
              <v-list-item v-for="(item, index) in item.children" :key="index" @click="item.click && item.click()" dense :class="item.class">
                <v-list-item-action class="ma-1">
                  <v-icon :size="item.iconSize || 25"> {{ item.icon }} </v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title :class="['px-2 body-2 font-weight-medium', $vuetify.theme.dark ? '' : 'grey--text text--darken-3']">
                    <span> {{ item.title }} </span>
                    <v-icon class="ma-1" size="15" v-if="$i18n.locale === item.langcode" color="primary darken-1"> mdi-checkbox-marked-circle </v-icon>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </div>
          </template>
        </v-card>
      </template>
      <template #append>
        <v-card tile flat :color="$vuetify.theme.dark ? '' : 'grey'">
          <v-btn block height="55" tile @click="logoutClickHandler" :color="$vuetify.theme.dark ? '#181818' : 'grey lighten-3'">
            <v-icon left size="22"> mdi-logout </v-icon>
            <span class="text-capitalize body-1 font-weight-medium"> {{ $t('logout') }} </span>
          </v-btn>
        </v-card>
      </template>
    </v-navigation-drawer>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { decrypt } from '../utils/crypto'
import { getUpdatedTimeZone } from '@/plugins/axios_settings'
// import { updateTimeZone } from '@/assets/js/filters'
export default {
  props: ['value'],
  data () {
    return {
      showMenubar: false,
      avatar: [],
      availableLanguages: []
    }
  },
  computed: {
    ...mapGetters(['userDetails', 'getTenantProfileObj', 'listOfTenantsAndWorkspaces']),
    userTimezone () {
      const timezone = (this.userDetails) ? localStorage.getItem((`${process.env.VUE_APP_NAME}_${this.userDetails.tenantname}_tz`)) : null
      return (timezone && timezone !== 'undefined') ? JSON.parse(timezone) : Intl.DateTimeFormat().resolvedOptions().timeZone
    },
    profileMenuList () {
      const menuList = []
      menuList.push({ text: this.$t('change'), icon: 'mdi-camera-retake-outline', click: () => this.$refs.file.$refs.input.click() })
      if (this.userDetails.userimage) {
        menuList.push({
          text: this.$t('remove'),
          icon: 'mdi-file-remove-outline',
          click: () => {
            this.$root.$emit('delete', {
              module: `users/remove_profile/${this.userDetails._id}`,
              onResolve: () => {
                this.userDetails.userimage = ''
                this.$store.commit('setProfileImage', '')
                this.updateAppUserCookie()
              }
            })
          }
        })
      }
      return menuList
    },
    menuItems () {
      return [
        // {
        //   title: this.$t('switchWorkspace'),
        //   icon: 'mdi-account-switch',
        //   switchworkspace: true,
        //   // click: () => this.$router.push({ path: '/tenantsavailable', query: { userswitchworkspace: 'true' } }).catch(() => {}),
        //   click: () => this.switchWorkPlaceHandler()
        // },
        {
          title: this.$t('whatsNew'),
          icon: 'mdi-new-box',
          click: () => this.$router.push('/whatsnew').catch(() => {})
        }, {
          title: this.$t('language'),
          icon: 'mdi-translate',
          class: 'pl-5',
          iconSize: 27,
          children: this.availableLanguages
        }
      ]
    },
    currentlyLoggedInTenant () {
      const userObj = this.$cookie.get(process.env.VUE_APP_USER) ? decrypt(JSON.parse(this.$cookie.get(process.env.VUE_APP_USER))) : null
      return (userObj) ? userObj.tenantname : ''
    }
  },
  mounted () {
    this.getAvailableLanguages()
    this.$root.$on('profileMenuBar', () => {
      this.showMenubar = true
    })
    this.$vuetify.theme.dark = JSON.parse(localStorage.getItem(`${process.env.VUE_APP_NAME}_dark_theme`)) ? JSON.parse(localStorage.getItem(`${process.env.VUE_APP_NAME}_dark_theme`)) : false
  },
  methods: {
    getAvailableLanguages () {
      this.$api.execute('get', `languages/get_by_domain?domain=${this.$store.state.auth.userDetails.domain}`)
        .then(({ data }) => {
          this.availableLanguages = []
          if (data && data.length) {
            for (const langItem of data) {
              if (langItem.isactive) {
                this.availableLanguages.push({
                  title: langItem.name,
                  icon: 'mdi-flag-checkered',
                  iconSize: 22,
                  langcode: langItem.code,
                  click: () => this.changeLang(langItem.code)
                })
              }
            }
          }
        })
    },
    uploadProfile () {
      var filesList = this.avatar
      var formData = new FormData()
      if (filesList) {
        formData.append(filesList.name, filesList)
        this.$api.execute('post', `users/upload_profile/${this.userDetails._id}`, formData)
          .then(({ data }) => {
            this.$root.$emit('snackbar', { snackbar: true, color: 'success', text: 'imageUploadMsg' })
            this.userDetails.userimage = data.userimage || data.profile
            this.$store.state.auth.userDetails.userimage = data.userimage || data.profile
            this.updateAppUserCookie()
            this.avatar = []
          })
      }
    },
    toggleAppTheme () {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark
      localStorage.setItem(`${process.env.VUE_APP_NAME}_dark_theme`, JSON.stringify(this.$vuetify.theme.dark))
    },
    changeLang (language) {
      this.$i18n.locale = language
      this.$cookie.set(process.env.VUE_APP_LOCALE, language)
      this.$router.push(`${this.$route.path}?lang=${language}`)
    },
    logoutClickHandler () {
      this.$vuetify.theme.dark = false
      this.$store.dispatch('logout')
      this.$router.push('/login')
    },
    /* handler for switch workspace */
    switchWorkPlaceHandler () {
      var date = new Date()
      date.setDate(date.getDate() + 4)
      this.$cookie.set(process.env.VUE_APP_NAME + '_secondary_tokenPWA', this.$cookie.get(process.env.VUE_APP_TOKEN), { expires: date })
      this.$store.dispatch('logout')
      this.$router.push({ path: '/tenantsavailable', query: { userswitchworkspace: 'true' } }).catch(() => {})
      this.$vuetify.theme.dark = false
      this.$root.$emit('removeTransition', true)
    },
    switchTenant (tenant) {
      if (tenant._id !== this.userDetails.tenantid) {
        this.$store.state.common.loadRouter = true
        const model = {
          authtoken: this.$store.state.auth.authToken,
          // email: this.userDetails.email,
          username: this.userDetails.email,
          domain: tenant.domain
        }
        this.$api.execute('post', 'auth/switch_user_workspace', model)
          .then(({ data }) => {
            this.$store.state.common.loadRouter = false
            if (data) this.afterTenantCreationHandler(data, false)
            // update timezone in moment
            this.$formatter.updateTimeZone()
            const timezone = getUpdatedTimeZone()
            // updateTimeZone()
            this.$moment.tz.setDefault(timezone)
          }).catch(() => {
            this.$store.state.common.loadRouter = false
            this.$router.push('/login')
          })
      }
    }
  },
  beforeDestroy () {
    this.$root.$off('profileMenuBar')
  }
}
</script>
<style scoped>
  .profilepage-camera {
    position: absolute;
    right: 10px;
    bottom: 5px;
    z-index: 5;
  }
</style>
